var Music = function() {
  this.animationId = null;
  this.isStop = false;

  this.soundArray = new Uint8Array(12);
  this.soundArray[0] = 0;
  this.soundArray[1] = 0;
  this.soundArray[2] = 0;
  this.soundArray[3] = 0;
  this.soundArray[4] = 0;
  this.soundArray[5] = 0;
  this.soundArray[6] = 0;
  this.soundArray[7] = 0;
  this.soundArray[8] = 0;
  this.soundArray[9] = 0;
  this.soundArray[10] = 0;
  this.soundArray[11] = 0;

  this._prepareAPI();
};

Music.prototype = {
  _prepareAPI: function() {
    window.requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame;
    window.cancelAnimationFrame = window.cancelAnimationFrame || window.webkitCancelAnimationFrame || window.mozCancelAnimationFrame || window.msCancelAnimationFrame;
  },

  start: function() {
    this.isStop = false;
    this._drawSpectrum(this);
  },

  stop: function() {
    var that = this;
    if (this.animationId != null) {
      this.isStop = true;
      setTimeout(function() {
        cancelAnimationFrame(that.animationId);
        that.animationId = null;
      }, 1000);
    }
  },

  _changeArray: function(that, step) {
    var tempArray = new Array(6);
    tempArray[0] = Math.round(12 * Math.random());
    tempArray[1] = Math.round(12 * Math.random());
    tempArray[2] = Math.round(12 * Math.random());
    tempArray[3] = Math.round(12 * Math.random());
    tempArray[4] = Math.round(12 * Math.random());
    tempArray[5] = Math.round(12 * Math.random());

    for (var i = 0; i < 6; i++) {
      that.soundArray[tempArray[i]] = 10 * step * Math.random();
    }
  },

  _drawSpectrum: function(that) {
    var canvas = document.getElementById('canvas'),
      cwidth = canvas.width,
      cheight = canvas.height,
      meterWidth = 15, //矩形宽度
      gap = 10, //矩形之间的间隔
      capHeight = 2, //跳动符的高度
      capStyle = '#FFFFFF', //跳动符的样式
      meterNum = 800 / (meterWidth + gap), //要绘画的矩形个数
      capYPositionArray = [], //存储上一帧的跳动符的位置
      ctx = canvas.getContext('2d');

    ctx.globalAlpha = 0.7;
    that._changeArray(that, 0);

    var step = 0;
    var isUp = true;
    var drawMeter = function() {
      ctx.clearRect(0, 0, cwidth, cheight);
      if (!that.isStop) {
        for (var i = 0; i < 12; i++) {
          var value = that.soundArray[i];
          if (capYPositionArray.length < Math.round(meterNum)) {
            capYPositionArray.push(value); //存储跳动符的高度
          };
          //	                ctx.fillStyle = capStyle;
          //	                if (value < capYPositionArray[i]) {
          //	                    ctx.fillRect(i * (meterWidth + gap), cheight - (--capYPositionArray[i]), meterWidth, capHeight);
          //	                } else {
          //	                    ctx.fillRect(i * (meterWidth + gap), cheight - value, meterWidth, capHeight);
          //	                    capYPositionArray[i] = value;
          //	                };
          //	                ctx.fillStyle = that._brgba(capStyle, 0.2);
          ctx.fillStyle = capStyle;
          ctx.fillRect(i * (meterWidth + gap), cheight - value + capHeight, meterWidth, cheight); //the meter
        }

        if (isUp) {
          that._changeArray(that, step);
          step = step + 1;
          if (step > 10) {
            isUp = false;
          }
        } else {
          that._changeArray(that, step);
          step = step - 1;
          if (step < 1) {
            isUp = true;
          }
        }
      }
      setTimeout(function() { that.animationId = requestAnimationFrame(drawMeter); }, 150);
    }
    that.animationId = requestAnimationFrame(drawMeter);
  },

  showBottom: function() {
    var canvas = document.getElementById('canvas'),
      cwidth = canvas.width,
      cheight = canvas.height,
      meterWidth = 15, //矩形宽度
      gap = 10, //矩形之间的间隔
      capHeight = 2, //跳动符的高度
      capStyle = '#fff', //跳动符的样式
      meterNum = 800 / (meterWidth + gap), //要绘画的矩形个数
      ctx = canvas.getContext('2d');
    ctx.fillStyle = capStyle;
    for (var i = 0; i < 12; i++) {
      ctx.fillRect(i * (meterWidth + gap), cheight, meterWidth, capHeight);
    }
  },

}
